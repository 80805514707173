(function (win, doc) {
    'use strict';

    var body = doc.body || doc.getElementsByTagName('body')[0];

    function ScrollFollow (id) {
        this.id = id;
        this.el = doc.getElementById(this.id);

        if (!'addEventListener' in doc ||
            !'scrollTop' in body ||
            !'requestAnimationFrame' in win) {
            return;
        }

        var self = this;

        this.origOffset = this.el.offsetTop;

        function scrollFollow () {
            win.requestAnimationFrame(function () {
                self.onscroll.apply(self);
            });
        }

        win.addEventListener('scroll', scrollFollow, false);

        this.onscroll();

        this.destroy = function () {
            this.el.parentNode.classList.remove('is-sticky');
            win.removeEventListener('scroll', scrollFollow);
        };
    };

    ScrollFollow.fn = ScrollFollow.prototype;

    ScrollFollow.fn.onscroll = function () {
        var bodyTop = body.scrollTop;
        var offset = bodyTop - this.origOffset;

        if (bodyTop > this.origOffset) {
            this.el.parentNode.classList.add('is-sticky');
        } else {
            this.el.parentNode.classList.remove('is-sticky');
        }
    };

    win.ScrollFollow = ScrollFollow;

})(window, document);