var ScrollSpy;

ScrollSpy = (function() {
  function ScrollSpy(navId) {
    if (!'addEventListener' in document ||
      !'requestAnimationFrame' in window) {
      return;
    }

    var a, aElements, i, len, self;
    this.navId = navId;
    this.track = {};
    aElements = document.getElementById(this.navId).getElementsByTagName('a');
    for (i = 0, len = aElements.length; i < len; i++) {
      a = aElements[i];
      this.track[a.attributes.href.value.substr(1)] = a;
    }
    self = this;
    window.addEventListener('scroll', function() {
        window.requestAnimationFrame(function () {
            self.onscroll.apply(self);
        });
    }, false);
    this.onscroll();
  }

  ScrollSpy.prototype.onscroll = function() {
    var elem, elemTop, id, leastTop, navElem, targetElem, top;
    top = document.documentElement.scrollTop;
    leastTop = 99999;
    targetElem = false;
    for (id in this.track) {
      elem = document.getElementById(id);
      elemTop = Math.abs(elem.getBoundingClientRect().top);
      if (elemTop < leastTop) {
        leastTop = elemTop;
        navElem = this.track[id];
        targetElem = elem;
      }
    }
    if (this.prevElem) {
      this.prevElem.className = '';
    }
    navElem.className = 'is-active';
    return this.prevElem = navElem;
  };

  return ScrollSpy;

})();

window.ScrollSpy = ScrollSpy;