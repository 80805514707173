'use strict';

(function (win, doc, F) {
    'use strict';

    // Safari 5.1 svg logo fix
    var ua = navigator.userAgent.toLowerCase();
    var chrome = /\bchrome\b/.test(ua);
    var safari = !chrome && /safari/.test(ua);
    var safari3 = safari && /version\/3/.test(ua);
    var safari4 = safari && /version\/4/.test(ua);
    var safari5 = safari && /version\/5/.test(ua);
    var oldsafari = safari3 || safari4 || safari5;
    if (oldsafari) {
        if ('querySelectorAll' in doc) {
            var logo = doc.querySelector('.Logo img');
            logo && logo.onerror();
        }
    }

    var support = F.support || false;

    if (!support.csspositionfixed) {
        return;
    }

    // ScrollSpy
    new ScrollSpy('menu');

    // smoothScroll + scrollfollow
    var smooth;
    enquire.register('screen and (min-width: 20em) and (max-width: 47.99em)', {
        match: function match() {
            smooth = new ScrollFollow('navigation');

            smoothScroll.init({
                offset: 39
            });
        },
        unmatch: function unmatch() {
            smooth && smooth.destroy();
        }
    });

    enquire.register('screen and (min-width: 48em)', {
        match: function match() {
            smoothScroll.init({
                offset: 96
            });
        }
    });
})(window, document, window.FIBA || false);